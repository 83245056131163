<template>
  <div class="stack">
    <div class="stack-header">
      <tabs :list="nav" button="network" class="medium-title hack">
        <template v-slot:item="{ item }">
          <router-link active-class="active" :to="item.to">
            {{ item.title }}
          </router-link>
        </template>
        <template #button>
          <!--            v-if="!isLoading || !updateRoute"-->
          <base-button
            v-if="!updateRoute"
            :icon="isIcon"
            :color="isColor"
            :tooltip="{
              content: text,
              autoHide: false,
              placement: 'left-start',
              container: 'body',
              trigger: 'click hover',
            }"
            class="subnet-info__create-btn"
            @click="clickActionButton()"
            >{{ $t(`text.${button?.route}`) }}
          </base-button>
        </template>
      </tabs>
    </div>
    <transition name="bubble">
      <router-view @change-type-create-button="changeView($event)"></router-view>
    </transition>
  </div>
</template>

<script>
import tabs from '@/components/Tabs/Tabs';
import { IS_PROD } from '@/layouts/LoginPage/mixins/currentProvider';
import newPrivateNetwork from '@/mixins/newPrivateNetwork';
import newIp from '@/mixins/newIp';
import newPublicNetwork from '@/mixins/newPublicNetwork';
import newRouter from '@/mixins/newRouter';
import newFirewall from '@/mixins/newFirewall';

export default {
  name: 'NetworkContainerNav',
  components: {
    tabs,
  },
  mixins: [newPrivateNetwork, newIp, newPublicNetwork, newRouter, newFirewall],
  data() {
    return {
      button: {
        type: 'network',
        quota: false,
        route: 'network',
      },
      updateRoute: true,
      currentQuota: 0,
      navRaw: [
        {
          title: this.$t('nav.network'),
          to: { name: 'viewStackPrivate' },
        },
        {
          title: this.$t('nav.floatip'),
          to: { name: 'viewStackFloatingIp' },
        },
        {
          title: this.$t('nav.networkext'),
          to: { name: 'viewStackPublic' },
        },
        {
          title: this.$t('nav.router'),
          to: { name: 'viewStackRouter' },
        },
        {
          title: this.$t('nav.firewall'),
          to: { name: 'viewStackFirewall' },
        },
      ],
    };
  },
  computed: {
    isIcon() {
      return this.currentQuota === 0 ? 'error' : '';
    },
    networksPublic() {
      return this.$store.state.moduleStack.networks
        .filter(x => x['router:external'] === false)
        .filter(x => x.shared === true)
        .map(x => x.id);
    },
    subnetPublic() {
      return this.$store.state.moduleStack.subnets.filter(x =>
        this.networksPublic.includes(x.network_id)
      );
    },
    newQuota() {
      return this.$store.state.moduleStack.quotasNetworkValue;
    },

    isColor() {
      // return this.button?.quota ? 'warn' : 'primary';
      // return this.availableQuotas(this.button.route) ? 'warn' : 'primary';
      return this.currentQuota === 0 ? 'warn' : 'primary';
    },
    publicQuota() {
      return this.newQuota.find(x => x.name === 'public').value - this.subnetPublic.length;
    },
    routerQuota() {
      return this.newQuota.find(x => x.name === 'router').value;
    },
    networkQuota() {
      return this.newQuota.find(x => x.name === 'network').value;
    },
    floatQuota() {
      return this.newQuota.find(x => x.name === 'floatingip').value;
    },
    quotaLink() {
      return IS_PROD
        ? `${window.location.origin}/#/stack/${this.$attrs.tariff.id}/quotas`
        : `http://localhost:8080/#/stack/${this.$attrs.tariff.id}/quotas`;
    },
    text() {
      if (this.currentQuota === 0) {
        return `Вы достигли квоты по количеству ${this.$t(
          `hintText.${this.button.route}`
        )}.<br /><br />
Перейдите в раздел <strong><a href="${this.quotaLink}">Квоты</a></strong> для увеличения. `;
      } else {
        return `Создать ${this.$t(`hint.${this.button.route}`)}`;
      }
    },
    currentRoute() {
      return this.$route.path;
    },
    nav() {
      return this.navRaw.filter(
        i => !i.access || (i.access && this[i.access.name] === i.access.value)
      );
    },
  },
  watch: {
    isLoading(event) {
      if (!event) {
        this.setRoute(this.$route.path);
        this.availableQuotas(this.button.route);
      }
    },
    publicQuota(event) {
      this.currentQuota = event;
    },
    networkQuota(event) {
      this.currentQuota = event;
    },
    routerQuota(event) {
      this.currentQuota = event;
    },
    floatQuota(event) {
      this.currentQuota = event;
    },
    currentRoute: {
      handler: function (event) {
        if (event.includes('/network/private')) this.button.route = 'network';
        else if (event.includes('/network/public')) this.button.route = 'public';
        else if (event.includes('/network/floatingips')) this.button.route = 'float';
        else if (event.includes('/network/router')) this.button.route = 'router';
        else if (event.includes('/network/firewall')) this.button.route = 'firewall';
        this.availableQuotas(this.button.route);
      },
      immediate: false,
    },
  },
  mounted() {
    this.setRoute(this.$route.path);
  },
  methods: {
    availableQuotas(type) {
      let quota;
      const allQuotasNames = [
        { type: 'network', v: 'network', k: true },
        { type: 'float', v: 'floatingip', k: true },
        { type: 'router', v: 'router', k: true },
        { type: 'firewall', v: 'security_group', k: true },
        { type: 'public', v: 'public_network', k: false },
      ];
      let currentQuota = allQuotasNames.find(x => x.type === type);
      if (currentQuota.k && this.$store.state.moduleStack.quotasNetwork[currentQuota.v]) {
        quota = this.newQuota.find(x => x.name === currentQuota.v).value;
      } else if (!currentQuota.k && !!this.$store.state.moduleStack.quota_limits?.public_network) {
        quota = this.newQuota.find(x => x.name === 'public').value - this.subnetPublic.length;
      } else quota = 0;
      this.currentQuota = quota;
      this.updateRoute = false;
    },
    setRoute(route) {
      if (route.includes('/network/private')) this.button.route = 'network';
      else if (route.includes('/network/public')) this.button.route = 'public';
      else if (route.includes('/network/floatingips')) this.button.route = 'float';
      else if (route.includes('/network/router')) this.button.route = 'router';
      else if (route.includes('/network/firewall')) this.button.route = 'firewall';
      this.availableQuotas(this.button.route);
    },
    clickActionButton() {
      if (this.button?.quota) {
        return '';
      } else {
        switch (this.button.route) {
          case 'network':
            return this.newPrivateNetwork();
          case 'float':
            return this.newIp().then(() => this.setRoute(this.$route.path));
          case 'public':
            return this.newPublicNetwork();
          case 'router':
            return this.newRouter();
          case 'firewall':
            return this.newFirewall();
          default:
            return this.newPrivateNetwork();
        }
      }
    },

    changeView(event) {
      console.log('----------', event);
      // this.updateRoute = true;
      this.button = Object.assign(this.button, event);
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "title": {
      "server": "Сети",
      "plan": "Тарифный план",
      "stats": "Статистика"
    },
    "text": {
      "network": "Новая приватная сеть",
      "float": "Новый IP - адрес",
      "public": "Новая публичная подсеть",
      "router": "Новый роутер",
      "firewall": "Новый файрвол"
    },
    "hint": {
      "network": "новую приватную сеть",
      "float": "новый IP - адрес",
      "public": "новую публичную подсеть",
      "router": "новый роутер",
      "firewall": "новый файрвол"
    },
    "hintText": {
      "network": "новых приватных сетей",
      "float": "новых IP - адресов",
      "public": "новых публичных подсетей",
      "router": "новых роутеров",
      "firewall": "новых файрволов"
    },
    "prolong": {
      "confirm" : "Сменить",
      "order": "Отмена"
    },
    "sure": {
      "confirm": "Подтвердить"
    },
    "nav": {
      "network": "Приватные сети",
      "floatip": "Плавающие IP",
      "networkext": "Публичные подсети",
      "router": "Роутеры",
      "firewall": "Файрвол"
    },
    "newServer": "Новая сеть",
    "activeUnder": "Действует до {date}",
    "actions": {
      "prolong": "Продлить",
      "changePlan": "Сменить тариф"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.hack{
  width: -moz-available;
  width:-webkit-fill-available;
}
.stack {
  display: flex;
  flex-direction: column;
  min-size(100%);
  flex: 1 1 100%;
  &-header {
    //+breakpoint(sm-and-up) {
      //flexy(space-betweeb);
      //flex-direction: column;
    //}

    //&__content {
    //  flexy(space-between, center);

      +breakpoint(sm-and-up) {
        flexy(flex-start, center);
        width: 100%;
        //justify-content: flex-start;
        //margin-right: 1.5rem;
        margin-top: 1.75rem;
        margin-bottom: 1.5rem;
        //flex: 1 1 auto;
      //}
    }
  }
  &-info {
    +breakpoint(sm-and-up) {
      flexy(space-betweeb, center);
    }

    &__content {
      flexy(space-between, center);

      +breakpoint(sm-and-up) {
        justify-content: flex-start;
        //margin-right: 1.5rem;
        flex: 1 1 auto;
      }
    }

    &__loader {
      flexy(center, center);
      flex: 1 1 auto;
    }

    &__create {
      flex: 0 0 auto;
      margin-top: 1.5rem;
      max-width: 160px;

      +breakpoint(sm-and-up) {
        margin: 0 0 0 auto;
      }
    }
  }

  &-mode {
    flexy(flex-end, center);
    margin-left: auto;

    +breakpoint(sm-and-up) {
      justify-content: flex-start;
      margin-left: 1.5rem;
    }

    &__btn {
      & + & {
        margin-left: 1rem;
      }
    }
  }

  &-content {
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-top: 2rem;
    }
    +breakpoint(md-and-up) {
      margin-top: 2.5rem;
    }
  }
}
.tariff-plan {

  &__btn {
    min-width: 160px;
    & + & {
      margin-top: 14px;

      +breakpoint(sm-and-up) {
        margin-top: 0;
        margin-left: 1.5rem;
      }
    }
  }
}
</style>
